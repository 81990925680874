/* You can add global styles to this file, and also import other style files */
ion-item.item-has-focus ion-label {
  color: inherit !important;
}

.text-align-center {
  text-align: center;
}

input,
ion-input {
  font-size: 16px;
}
